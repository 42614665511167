import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { AddressItemType } from '../../../models/address'
import { OrderType, PaymentMethodItem, ShippingMethod } from '../../../models/order'
import { StoreType } from '../../../models/types'

interface error {
  message: string
  type: string
}
interface errorsReceive {
  email: error
  userName: error
  phoneNumber: error
}
export const slice = createSlice({
  name: 'createOrderPage',
  initialState: {
    loading: false,
    paymentMethod: [] as PaymentMethodItem[],
    shippingMethod: [] as ShippingMethod[],
    order: undefined as OrderType | undefined,
    addressList: [] as AddressItemType[],
    selectedAddressId: 0,
    isUpdateAddress: false,
    isSelectAddress: true,
    selectedVoucherId: 0,
    confirmSuccess: false,

    //receiveGoodsAtThePharmacy
    type: 1,
    date: new Date(),
    time: '',
    storeSelected: {} as StoreType,
    userName: '',
    phoneNumber: '',
    email: '',
    errorsReceive: {} as errorsReceive,
  },
  reducers: {
    changeStateData: (state, action) => {
      state.paymentMethod = action.payload.payment_method || []
      state.shippingMethod = action.payload.shipping_method || []
      state.order = action.payload.order
    },
    changeAddressId: (state, action) => {
      state.selectedAddressId = action.payload
    },
    changeVoucherId: (state, action) => {
      state.selectedVoucherId = action.payload
    },
    changeIsUpdateAddress: (state, action) => {
      state.isUpdateAddress = action.payload
    },
    changeIsSelectAddress: (state, action) => {
      state.isSelectAddress = action.payload
    },
    changeConfirmSuccess: (state, action) => {
      state.confirmSuccess = action.payload
    },
    //receiveGoodsAtThePharmacy
    changeTypeReceive: (state, action) => {
      state.type = action.payload
    },
    changeUserReceive: (state, action) => {
      state.userName = action.payload.userName
      state.phoneNumber = action.payload.phoneNumber
      state.email = action.payload.email
    },
    changeTimeReceive: (state, action) => {
      state.time = action.payload
    },
    changeDateReceive: (state, action) => {
      state.date = action.payload
    },
    changeStoreReceive: (state, action) => {
      state.storeSelected = action.payload
    },
    changeErrorsReceive: (state, action) => {
      state.errorsReceive = action.payload
    },
    changeInfoUserReceive: (state, action) => {
      const { key, value } = action.payload as { key: 'email' | 'phoneNumber' | 'userName'; value: string }
      state[key] = value
    },
    changeRemoveVoucherId: (state) => {
      state.selectedVoucherId = 0
    },
  },
})

export const selectPaymentMethod = (state: RootState) => state[slice.name].paymentMethod
export const selectOrder = (state: RootState) => state[slice.name].order
export const selectAddressList = (state: RootState) => state[slice.name].addressList
export const selectLoading = (state: RootState) => state[slice.name].loading
export const selectShippingMethod = (state: RootState) => state[slice.name].shippingMethod
export const selectSelectedAddressId = (state: RootState) => state[slice.name].selectedAddressId
export const selectSelectedVoucherId = (state: RootState) => state[slice.name].selectedVoucherId
export const selectIsUpdateAddress = (state: RootState) => state[slice.name].isUpdateAddress
export const selectIsSelectAddress = (state: RootState) => state[slice.name].isSelectAddress
export const selectConfirmSuccess = (state: RootState) => state[slice.name].confirmSuccess
export const selectReceiveGoodsAtThePharmacy = (state: RootState) => state[slice.name]

export const {
  changeStateData,
  changeAddressId,
  changeVoucherId,
  changeIsUpdateAddress,
  changeIsSelectAddress,
  changeConfirmSuccess,
  changeTypeReceive,
  changeUserReceive,
  changeDateReceive,
  changeTimeReceive,
  changeStoreReceive,
  changeErrorsReceive,
  changeInfoUserReceive,
  changeRemoveVoucherId,
} = slice.actions

export const CreateOrderReducer = slice.reducer
