export const formatPriceVND = (value: number | undefined) => {
  if (!value) return 0
  return `${value.toLocaleString('en-US')}đ`
}

export const formatPriceK = (value: number | undefined) => {
  if (value === undefined) return
  if (value < 1000) {
    return `${value}đ`
  }
  const newValue = value / 1000
  return `${newValue}K`
}

export const formatWithThousand = (value: number | undefined, maxCount = 2, minCount = 0, locale = 'en') => {
  if (value === undefined || value === 0) return '0'
  if (value < 1000) return value
  const result = value / 1000
  const formattedValue = result.toLocaleString(locale, {
    minimumFractionDigits: minCount,
    maximumFractionDigits: maxCount,
  })

  return `${formattedValue}k`
}

export const formatPrice = (value: number | undefined) => {
  if (!value) return 0
  return `${value.toLocaleString('en-US')}`
}

// caculate price in order detail and cart
type ItemType = {
  price: number
  price_origin?: number
  is_combo: number
  deal_type?: number
  type?: number
  items?: {
    price: number
    price_origin: number
    is_main: number
  }[]
}

export const handleItemPrice = (item: ItemType, key: 'price_origin' | 'price') => {
  // combo nhiều loại sản phẩm
  if (item.is_combo && item.type) {
    if (key === 'price_origin') {
      const itemFilter = item?.items?.find((e) => e.is_main)?.price_origin
      return itemFilter ?? 0
    }
    const price = item.items?.reduce((acc, item) => {
      return acc + item[key]
    }, 0)
    return price ?? 0
  } else {
    return item[key] ?? 0
  }
}
