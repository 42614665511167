import { createSlice } from '@reduxjs/toolkit'
import { toastSuccessErr } from '../../../helpers/toast'
import { BriefProduct } from '../../../models/product'
import { RootState } from '../../store'
import {
  getCampaignList,
  getCategoryList,
  getFlashSaleList,
  getSeenList,
  getSuggestTodayList,
} from './product-list-api'

const slice = createSlice({
  name: 'productListSlice',
  initialState: {
    loading: false,
    seenList: [] as BriefProduct[],
    campaignList: [] as BriefProduct[],
    categoryList: [] as BriefProduct[],
    flashSaleList: [] as BriefProduct[],
    suggestTodayList: [] as BriefProduct[],
  },
  reducers: {},
  extraReducers: (builder) => {
    // Lấy danh sách đã xem
    builder
      .addCase(getSeenList.pending, (state) => {
        state.loading = true
      })
      .addCase(getSeenList.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          state.seenList = action.payload.data
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
        state.loading = false
      })
      .addCase(getSeenList.rejected, (state) => {
        state.loading = false
      })

    // Lấy danh sách theo khuyến mãi
    builder
      .addCase(getCampaignList.pending, (state) => {
        state.loading = true
      })
      .addCase(getCampaignList.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          state.campaignList = action.payload.data
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
        state.loading = false
      })
      .addCase(getCampaignList.rejected, (state) => {
        state.loading = false
      })

    builder
      .addCase(getFlashSaleList.pending, (state) => {
        state.loading = true
      })
      .addCase(getFlashSaleList.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          state.flashSaleList = action.payload.data
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
        state.loading = false
      })
      .addCase(getFlashSaleList.rejected, (state) => {
        state.loading = false
      })

    // Lấy danh sách theo category
    builder
      .addCase(getCategoryList.pending, (state) => {
        state.loading = true
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          state.categoryList = action.payload.data
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
        state.loading = false
      })
      .addCase(getCategoryList.rejected, (state) => {
        state.loading = false
      })

    // Lấy danh sách gợi ý
    builder
      .addCase(getSuggestTodayList.pending, (state) => {
        state.loading = true
      })
      .addCase(getSuggestTodayList.fulfilled, (state, action) => {
        const { code, message } = action.payload as any
        if (code === 200) {
          state.suggestTodayList = action.payload.data
        }
        if (code != 200) {
          toastSuccessErr({ type: 'error', message: message })
        }
        state.loading = false
      })
      .addCase(getSuggestTodayList.rejected, (state) => {
        state.loading = false
      })
  },
})

export const getProductListStore = (state: RootState) => state[slice.name]
export const ProductListReducer = slice.reducer
