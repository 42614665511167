import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { PromotionType } from '../../../models/voucherType'

export const slice = createSlice({
  name: 'voucherPageCart',
  initialState: {
    voucherData: [] as Array<PromotionType>,
    detailVoucher: {} as PromotionType,
    idVoucherSelected: null as any,
  },
  reducers: {
    changeVoucherData: (state, action) => {
      state.voucherData = action.payload
    },
    changeDetailVoucher: (state, action) => {
      state.detailVoucher = action.payload
    },
    changeSelectedVoucher: (state, action) => {
      state.idVoucherSelected = action.payload
    },
    changeRemoveVoucher: (state) => {
      state.idVoucherSelected = 0
    },
  },
})

export const selectVoucherData = (state: RootState) => state[slice.name].voucherData
export const detailVoucher = (state: RootState) => state[slice.name].detailVoucher
export const idVoucherSelected = (state: RootState) => state[slice.name].idVoucherSelected

export const { changeVoucherData, changeDetailVoucher, changeSelectedVoucher, changeRemoveVoucher } = slice.actions

export const VoucherPageCartReducer = slice.reducer
