import { createApi } from '@reduxjs/toolkit/query/react'
import Network, { API_ROOT } from '../../../apis/Network'
import { ResponseData } from '../../../models/types'
import { axiosBaseQuery } from '../../hooks'
import { ContactType } from '../../../models/contact'
import { BrandVideoType } from '../../../models/brandVideo'

export const brandVideoApi = createApi({
    reducerPath: 'brandVideoApi',
    baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
    endpoints: (build) => ({
      getBrandVideoList: build.query<BrandVideoType[] | undefined, unknown>({
          query: () => ({
            url: Network.brandVideo.getList,
            method: 'get',
          }),
          transformResponse: (rawResult: ResponseData<BrandVideoType[]>) => {
            return rawResult.data
          },
      }),
    }),
})

export const { useGetBrandVideoListQuery } = brandVideoApi

export default brandVideoApi
