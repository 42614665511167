import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../hooks'
import Network, { API_ROOT } from '../../../apis/Network'
import { AddressItemDtoType, AddressItemType } from '../../../models/address'
import { ResponseData } from '../../../models/types'
import { toastSuccessErr } from '../../../helpers/toast'

const userAddressApi = createApi({
  reducerPath: 'userAddressApi',
  baseQuery: axiosBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['Address'],
  endpoints: (build) => ({
    getAddressList: build.query<AddressItemType[] | undefined, unknown>({
      query: () => ({
        url: Network.userAddress.getList,
        method: 'GET',
      }),
      providesTags: ['Address'],
      transformResponse: (rawResult: ResponseData<AddressItemType[]>) => {
        return rawResult.data
      },
    }),

    getAddressItem: build.query<AddressItemType | undefined, unknown>({
      query: (id: string | number) => ({
        url: Network.userAddress.getItem(id),
        method: 'GET',
      }),
      providesTags: ['Address'],
      transformResponse: (rawResult: ResponseData<AddressItemType>) => {
        if (rawResult.code !== 200) {
          toastSuccessErr({ type: 'error', message: rawResult.message })
        }
        return rawResult.data
      },
    }),

    addAddressItem: build.mutation<AddressItemType | undefined, unknown>({
      query: (params: AddressItemDtoType) => ({
        url: Network.userAddress.addItem,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: ['Address'],
    }),

    updateAddressItem: build.mutation<boolean | undefined, unknown>({
      query: (params: AddressItemDtoType) => ({
        url: Network.userAddress.putItem + params?.id,
        method: 'put',
        data: params,
      }),
      invalidatesTags: ['Address'],
    }),

    deleteAddressItem: build.mutation<ResponseData<boolean> | undefined, unknown>({
      query: (id: string | number) => ({
        url: Network.userAddress.putItem + id,
        method: 'delete',
      }),
      invalidatesTags: ['Address'],
    }),
  }),
})

export const {
  useGetAddressListQuery,
  useGetAddressItemQuery,
  useAddAddressItemMutation,
  useDeleteAddressItemMutation,
  useUpdateAddressItemMutation,
} = userAddressApi
export default userAddressApi
