import React from 'react'
import { useTranslation } from 'react-i18next'
import ic_to from '../../assets/icons/VoucherScreen/ic_arrow_to.png'
import { toastSuccessErr } from '../../helpers/toast'
import { useAppDispatch } from '../../app/hooks'
import { changeSelectedVoucher } from '../voucher/store/slice'

type Props = {
  cartData: any
  totalItem: number
}

const Voucher: React.FC<Props> = ({ cartData, totalItem }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const handleClick = () => {
    if (!totalItem) {
      toastSuccessErr({ type: 'error', message: t('fastCart.noChooseItem') })
      return
    }
  }

  const handleChangeVoucher = () => {
    dispatch(changeSelectedVoucher(cartData?.promotion?.id))
  }

  return (
    <>
      <div className='product-cart-screen__wrap-voucher'>
        {!totalItem ? (
          <div className='product-cart-screen__voucher' onClick={handleClick}>
            {cartData?.promotion ? (
              <span>[{cartData?.promotion?.name}]</span>
            ) : (
              <button className='btn btn-link'>{t('fastCart.selectVoucher')}</button>
            )}
            <img src={ic_to} />
          </div>
        ) : (
          <div
            className='product-cart-screen__voucher'
            data-bs-toggle='modal'
            data-bs-target='#modalVoucher'
            onClick={handleChangeVoucher}
          >
            {cartData?.promotion ? (
              <span>[{cartData?.promotion?.name}]</span>
            ) : (
              <button className='btn btn-link'>{t('fastCart.selectVoucher')}</button>
            )}
            <img src={ic_to} />
          </div>
        )}
      </div>
    </>
  )
}

export default Voucher
