import { createAsyncThunk } from '@reduxjs/toolkit'
import productListAPI from '../../../apis/api/api-get-list-product'
import { GetCagetoryDto, GetCampaignDto, GetSuggestTodayDto } from '../../../models/product'

export const getSimilarList = createAsyncThunk('/ProductDetail/getSimilarList', async (slug: string) => {
  return await productListAPI.getSimilarList(slug)
})

export const getRecommendList = createAsyncThunk('/ProductDetail/getRecommendList', async (slug: string) => {
  return await productListAPI.getRecommendList(slug)
})

export const getSeenList = createAsyncThunk('/ProductDetail/getSeenList', async () => {
  return await productListAPI.getSeenList()
})

export const getCampaignList = createAsyncThunk('/ProductDetail/getCampaignList', async (params: GetCampaignDto) => {
  return await productListAPI.getCampaignList(params)
})
export const getFlashSaleList = createAsyncThunk('/ProductDetail/getFlashSaleList', async (params: GetCampaignDto) => {
  return await productListAPI.getFlashSaleList(params)
})

export const getCategoryList = createAsyncThunk('/ProductDetail/getCategoryList', async (params: GetCagetoryDto) => {
  return await productListAPI.getCategoryList(params)
})

export const getSuggestTodayList = createAsyncThunk(
  '/ProductDetail/getSuggestTodayList',
  async (params: GetSuggestTodayDto) => {
    return await productListAPI.getSuggestTodayList(params)
  },
)
